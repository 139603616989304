import Vue from "vue";
import sha256 from 'crypto-js/sha256';

let host = "tma-asigurari.ro"

if (window.location.href.includes('dev.tma-asigurari') || window.location.href.includes('localhost')) {
  host = "dev.tma-asigurari.ro"
}

export default {
  version: "1.3",
  BASE_URL: `https://${host}/api/index.php/`,
  BASE_URL_NO_INDEX: `https://${host}/api/`,
  URL_OCR: `https://${host}/src/ocr/`,
  baseURL: `https://${host}/api/`,

  // BASE_URL: "https://dev.tma-asigurari.ro/api/index.php/",
  // BASE_URL_NO_INDEX: "https://dev.tma-asigurari.ro/api/",
  // baseURL: "https://dev.tma-asigurari.ro/api/",
  // URL_OCR: "https://dev.tma-asigurari.ro/src/ocr/",


  set_token: function (newToken) {
    window.localStorage.setItem("pca-asigurari-token", newToken);
    Vue.http.headers.common["Token"] = newToken;
  },

  generate_avatar(nume, prenume) {
    return `https://ui-avatars.com/api/?name=${nume}+${prenume}`;
  },

  async downloadHandler(id, tip) {
    const partialLink = "./assets/documente/"; + tip + '/';
    const key = sha256(id.toString() + '!@#.<(%!|#,.#$/');

    window.open(
      this.BASE_URL + "download/download_files?id=" + id + "&link=" + partialLink + "&tip=" + tip + "&key=" + key.toString()
    );

    return;
  },

  get_token: function () {
    return window.localStorage.getItem("pca-asigurari-token");
  },

  set_drepturi: function (drepturi) {
    window.localStorage.setItem(
      "pca-asigurari-drepturi_user",
      JSON.stringify(drepturi)
    );
  },

  get_drepturi: function () {
    var drepturi = window.localStorage.getItem("pca-asigurari-drepturi_user");
    return JSON.parse(drepturi);
  },
  set_user_type: function (user_type) {
    window.localStorage.setItem("pca-asigurari-user_type", user_type);
  },

  get_user_type: function () {
    return window.localStorage.getItem("pca-asigurari-user_type");
  },

  is_logged_in: function () {
    var token = this.get_token();
    return token !== "";
  },

  logout: function () {
    this.set_token("");
    this.set_drepturi("");
  },

  redirect_to_homepage(vueInstance) {
    vueInstance.$router.push("/");
  },

  verify_response: function (response) {
    if (response.body.NotLogged) {
      return false;
    }
    return true;
  },

  verify_login_and_redirect: function (vueInstance) {
    if (!this.is_logged_in()) {
      vueInstance.$router.push("/");
    }
  },
};
